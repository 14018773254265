<template>
    <div class="flex flex-col items-center justify-center w-screen min-h-screen bg-gray-100 text-gray-800 p-10">
        <div class="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg overflow-hidden">
            <div v-if="assistant && thread" class="flex flex-col flex-grow h-0 p-4 overflow-auto">
                <div class="flex w-full mt-2 space-x-3 max-w-xs">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm">Hi! I'm your item identifier. Upload an image please</p>
                        </div>
                    </div>
                </div>
                <div v-for="(message, index) in messages" :key="index">
                    <div v-if="message.role === 'assistant'" class="flex w-full mt-2 space-x-3 max-w-xs">
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                        <div>
                            <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                            <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                                <p class="text-sm">{{ renderText(message.content[0].text.value) }}</p>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                        <div>
                            <div class="flex w-full justify-end pb-1 mt-1">
                                <span class="text-xs text-gray-500 leading-none">You</span>
                            </div>
                            <div class="bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg">
                                <p v-if="message.content[0].text" class="text-sm">{{ message.content[0].text.value }}</p>
                                <p v-else class="text-sm">{{ message.content[0].image_file.file_id }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    </div>
                </div>
                <div v-if="loadingResponse" class="flex w-full mt-2 space-x-3 max-w-xs">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm">Typing...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-300 p-4 flex">
                <input v-on:change="onFileChange" class="flex items-center h-10 w-full rounded px-3 text-sm" type="file">
            </div>
        </div>
    </div>
</template>
<script>
import OpenAiChat from '../mixins/OpenAiChat';

export default {
    mixins: [OpenAiChat],

    async created() {
        await this.initChat(process.env.VUE_APP_OPENAI_IMAGE_IDENTIFIER_ASSISTANT_ID);
    },

    methods: {
        async initChat(assistant_id) {
            this.assistant = await this.getAssistant(assistant_id);
            this.thread = await this.generateThread();
        },

        onFileChange(e) {
            this.files = e.target.files || e.dataTransfer.files;
            console.log(this.files[0])
            this.sendFile();
        },

        renderText(text) {
            if (text.includes('```json')) {
                text = text.replace('```json', '');
                text = text.replace('```', '');
                let result = JSON.parse(text);
                let resultString = '';
                result.items.forEach((item) => {
                    resultString += item.title + '\n' + item.price + ' ' + item.currency + '\n';
                });
                return resultString + 'Total: ' + result.total.price + ' ' + result.total.currency;
            }
            return text;
        }
    }
}
</script>
