
export default {
    data() {
        return {
            assistant: {},
            thread: {},
            run: {},
            messages: [],
            textField: "",
            loadingResponse: false,
            files: null,
            fileId: null,
            requiresAction: false,
            action: null,
        }
    },

    methods: {
        // Get assistant based on ID
        async getAssistant(assistant_id) {
            const assistant = await this.$openai.beta.assistants.retrieve(assistant_id);
            return {
                name: assistant.name,
                id: assistant.id,
            };
        },

        // Generate a new thread
        async generateThread() {
            const thread = await this.$openai.beta.threads.create();
            return {
                id: thread.id,
            };
        },

        // Send a message to the assistant (text)
        async postMessage() {
            this.messages.push({
                role: "user",
                content: [
                    {
                        text: {
                            value: this.textField
                        }
                    }
                ]
            });

            // Send message to OpenAI
            await this.sendMessage(this.textField)
                .then(async () => {
                    console.log("Message sent");
                });

            this.textField = "";

            // Run assistant
            this.run = await this.runAssistant();

            // Wait for run to complete and fetch messages
            await this.waitForRunToCompleteAndFetchMessages();
        },

        // Send a message to the assistant (API response)
        async postFunctionResponse(response) {
            // Send message to OpenAI
            await this.submitToolOutputs(response)
                .then(async () => {
                    console.log("Response sent");
                });

            // Wait for run to complete and fetch messages
            await this.waitForRunToCompleteAndFetchMessages();
        },

        // Send message to OpenAI in generated thread
        async sendMessage(content, role = "user") {
            await this.$openai.beta.threads.messages.create(this.thread.id, {
                role: role,
                content: content,
            });
        },

        // Send tool outputs to OpenAI (API response)
        async submitToolOutputs(output) {
            await this.$openai.beta.threads.runs.submitToolOutputsStream(
                this.thread.id,
                this.run.id,
                {
                    tool_outputs: [{
                        tool_call_id: this.action.tool_call_id,
                        output: output,
                    }]
                }
            );
        },

        // Run the assistant
        async runAssistant() {
            const run = await this.$openai.beta.threads.runs.create(this.thread.id, {
                assistant_id: this.assistant.id,
            });

            this.loadingResponse = true;

            return {
                id: run.id,
                status: run.status
            };
        },

        // Wait for run to complete and fetch messages
        async waitForRunToCompleteAndFetchMessages() {
            this.run = await this.getRunStatus();

            if(this.run.status !== "completed") {
                if (this.run.status === "requires_action" && !this.requiresAction) {
                    this.requiresAction = true;
                    this.action =
                        {
                            'name': this.run.required_action.submit_tool_outputs.tool_calls[0].function.name,
                            'args': JSON.parse(this.run.required_action.submit_tool_outputs.tool_calls[0].function.arguments),
                            'tool_call_id': this.run.required_action.submit_tool_outputs.tool_calls[0].id,
                        };
                }
                setTimeout(function () {
                    this.waitForRunToCompleteAndFetchMessages();
                }.bind(this), 2500)
                console.log(this.run.status);
            } else {
                console.log("Run completed");
                console.log("Fetching messages");
                this.requiresAction = false;
                this.action = null;

                await this.getMessages()
            }
        },

        // Get run status
        async getRunStatus() {
            const run = await this.$openai.beta.threads.runs.retrieve(this.thread.id, this.run.id);
            return {
                id: run.id,
                status: run.status,
                required_action: run.required_action,
            };
        },

        // Get messages from OpenAI
        async getMessages() {
            const messages = await this.$openai.beta.threads.messages.list(this.thread.id);

            let chatMessages = [];
            messages.body.data.forEach((message) => {
                chatMessages.push({
                    id: message.id,
                    role: message.role,
                    content: message.content,
                    created_at: message.created_at,
                });
            });

            this.loadingResponse = false;
            this.messages = chatMessages.reverse();
        },

        // Send a file to OpenAI
        async sendFile() {
            const response = await this.$openai.files.create({
                file: this.files[0],
                purpose: "vision",
            });


            this.messages.push({
                role: "user",
                content: [
                    {
                        type: "image_file",
                        image_file: {
                            file_id: response.id,
                        }
                    }
                ]
            });

            await this.sendMessage([
                {
                    type: "image_file",
                    image_file: {
                        file_id: response.id,
                    }
                }
            ], "user")
                .then(async () => {
                    console.log("File sent");
                });

            this.run = await this.runAssistant()
            await this.waitForRunToCompleteAndFetchMessages();
        }
    }
};
