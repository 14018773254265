import { createRouter, createWebHistory } from 'vue-router';
import ChatBot from './components/ChatBot.vue';
import ChatBotFunctions from './components/ChatBotFunctions.vue';
import ImageIdentifier from './components/ImageIdentifier.vue';

const routes = [
    {
        path: '/',
        name: 'ChatBot',
        component: ChatBot,
    },
    {
        path: '/functions',
        name: 'Functions',
        component: ChatBotFunctions,
    },
    {
        path: '/image-identifier',
        name: 'ImageIdentifier',
        component: ImageIdentifier,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });
  
  export default router;
