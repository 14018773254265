import { createApp } from 'vue'
import App from './App.vue'
import { OpenAI } from "openai";
import router from './router';

const openai = new OpenAI({
    apiKey: process.env.VUE_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

const app = createApp(App)
app.use(router);

app.config.globalProperties.$openai = openai;

app.mount('#app');