<template>
    <div class="flex flex-col items-center justify-center w-screen min-h-screen bg-gray-100 text-gray-800 p-10">
        <div class="flex flex-col flex-grow w-full max-w-xl bg-white shadow-xl rounded-lg overflow-hidden">
            <div v-if="assistant && thread" class="flex flex-col flex-grow h-0 p-4 overflow-auto">
                <div class="flex w-full mt-2 space-x-3 max-w-xs">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm">Hi! I'm your personal assistant. How can I help you?</p>
                        </div>
<!--                        <span class="text-xs text-gray-500 leading-none">2 min ago</span>-->
                    </div>
                </div>
                <div v-for="(message, index) in messages" :key="index">
                    <div v-if="message.role === 'assistant'" class="flex w-full mt-2 space-x-3 max-w-xs">
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                        <div>
                            <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                            <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                                <p class="text-sm">{{ message.content[0].text.value }}</p>
                            </div>
<!--                            <span class="text-xs text-gray-500 leading-none">2 min ago</span>-->
                        </div>
                    </div>
                    <div v-else class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
                        <div>
                            <div class="flex w-full justify-end pb-1 mt-1">
                                <span class="text-xs text-gray-500 leading-none">You</span>
                            </div>
                            <div class="bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg">
                                <p class="text-sm">{{ message.content[0].text.value }}</p>
                            </div>
<!--                            <span class="text-xs text-gray-500 leading-none">2 min ago</span>-->
                        </div>
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    </div>
                </div>
                <div v-if="loadingResponse" class="flex w-full mt-2 space-x-3 max-w-xs">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm">Typing...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-300 p-4 flex">
                <input v-model="textField" class="flex items-center h-10 w-full rounded px-3 text-sm" type="text"
                       placeholder="Type your message…" @keyup.enter="postMessage()">
                <div @click="postMessage()" class="px-3 py-2 bg-blue-700 text-white rounded">Send</div>
            </div>
        </div>
    </div>
</template>

<script>
import OpenAiChat from "@/mixins/OpenAiChat";
export default {
    mixins: [OpenAiChat],

    async created() {
        await this.initChat("asst_WLiMTxpMIp3x1kIc2wMbEXRU");
    },

    methods: {
        async initChat(assistant_id) {
            this.assistant = await this.getAssistant(assistant_id);
            this.thread = await this.generateThread();
        },
    }
}
</script>
