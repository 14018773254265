<template>
    <div class="flex flex-col items-center justify-center w-screen min-h-screen bg-gray-100 text-gray-800 p-10">
        <div class="flex flex-col flex-grow w-full max-w-screen-lg bg-white shadow-xl rounded-lg overflow-hidden">
            <div v-if="assistant && thread" class="flex flex-col flex-grow h-0 p-4 overflow-auto">
                <div class="flex w-full mt-2 space-x-3 ">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm text-left">Hi! I'm your personal assistant. Ask me a question and I will do my best to respond</p>
                        </div>
                    </div>
                </div>
                <div v-for="(message, index) in messages" :key="index">
                    <div v-if="message.role === 'assistant'" class="flex w-full mt-2 space-x-3">
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                        <div>
                            <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                            <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg mr-md">
                                <!--<p class="text-sm">{{ message.content[0].text.value }}</p>-->
                                <vue-markdown class="text-sm text-left max-w-xl overflow-auto" :source="message.content[0].text.value"></vue-markdown>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex w-full mt-2 space-x-3 ml-auto justify-end">
                        <div>
                            <div class="flex w-full justify-end pb-1 mt-1">
                                <span class="text-xs text-gray-500 leading-none">You</span>
                            </div>
                            <div class="bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg">
                                <p v-if="message.content[0].text" class="text-sm text-left">{{ message.content[0].text.value }}</p>
                                <p v-else class="text-sm text-left">{{ message.content[0].image_file.file_id }}</p>
                            </div>
                        </div>
                        <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    </div>
                </div>
                <div v-if="loadingResponse" class="flex w-full mt-2 space-x-3">
                    <div class="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                    <div>
                        <span class="text-xs text-gray-500 leading-none">{{ assistant.name }}</span>
                        <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                            <p class="text-sm text-left">Typing...</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-300 p-4 flex">
                                <input v-model="textField" class="flex items-center h-10 w-full rounded px-3 text-sm" type="text"
                                       placeholder="Type your message…" @keyup.enter="postMessage()">
                                <div @click="postMessage()" class="px-3 py-2 bg-blue-700 text-white rounded">Send</div>
            </div>
        </div>
    </div>
</template>
<script>
import OpenAiChat from '../mixins/OpenAiChat';
import VueMarkdown from 'vue-markdown-render'

export default {
    mixins: [OpenAiChat],

    components: {
        VueMarkdown
    },

    async created() {
        await this.initChat(process.env.VUE_APP_OPENAI_FUNCTIONS_ASSISTANT_ID);
    },

    watch: {
        requiresAction(val) {
            if (val) {
                // These actions is defined in the OpenAi Assistant dashboard
                // Based on the action name, we can call a specific function
                if (this.action.name === 'identify_person') {
                    this.identifyPerson();
                } else if (this.action.name === 'get_number_of_registered_users') {
                    this.getRegisteredPersons();
                } else if (this.action.name === 'get_number_of_active_users') {
                    this.getActiveUsers();
                }
            }
        }
    },

    methods: {
        async initChat(assistant_id) {
            this.assistant = await this.getAssistant(assistant_id);
            this.thread = await this.generateThread();
        },

        // These are example functions that can be called from the OpenAi Assistant
        
        identifyPerson() {
            window.axios.get('/api/identify/' + this.action.args.identifier)
                .then(response => {
                    console.log(response.data);
                    this.postFunctionResponse(response.data.message);
                })
                .catch(error => {
                    console.log(error);
                    this.postFunctionResponse(error.response.data.message);
                });
        },

        getRegisteredPersons() {
            window.axios.get('/api/registered-users', {
                params: {
                    from_date: this.action.args.from_date,
                    to_date: this.action.args.to_date
                }
            })
                .then(response => {
                    console.log(response.data);
                    this.postFunctionResponse(response.data.response);
                })
                .catch(error => {
                    console.log(error);
                    this.postFunctionResponse(error.response.data.response);
                });
        },

        getActiveUsers() {
            let params = {
                from_date: this.action.args.from_date,
                to_date: this.action.args.to_date,
                building_id: this.action.args.building_id
            }

            if (this.action.args.order_type) {
                params.order_type = this.action.args.order_type;
            }

            window.axios.get('/api/active-users', {
                params: params
            })
                .then(response => {
                    console.log(response.data);
                    this.postFunctionResponse(response.data.response);
                })
                .catch(error => {
                    console.log(error);
                    this.postFunctionResponse(error.response.data.response);
                });
        }
    }
}
</script>
